<template>
    <div class="faq">
        <Header :title="title"></Header>
        <div class="container">
            <h5 class="tit mt-2 mb-4 fw-bold">Q. {{item.question}}</h5>
            <pre class="small">{{item.answer}}</pre>
        
            <div>
                <br>
                <hr>
                <p class="small" style="font-weight:bold">더 궁금하신가요?</p>
                <router-link to="/cs/inquiry/add" class="btn w-100 bg-pale text-primary my-4">1:1 문의하기</router-link>
        
                <div class="mb-3 small">
                    <span class="text-grey">고객센터 운영시간<br>09:00 ~ 18:00, 주말 및 공휴일 제외</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: "FAQ",
            idx: this.$route.params.idx,
            item: {
                question:'아이디를 잊었습니다. 어떻게 찾을 수 있나요?',
                answer:'로그인 페이지 하단에 아이디/비밀번호 찾기로 내가 가입한 이메일 인증 또는 휴대폰 인증을 통해 찾을 수 있습니다. 이메일 인증 및 휴대폰 인증을 진행하지 않은 계정은 고객 센터로 문의주십시오.'
            }
        }
    },
    created(){
    },
    mounted() {
    },
    methods: {
    },
}
</script>