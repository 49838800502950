<template>
    <div class="inqurity">
        <Header :title="title"></Header>
        <div class="container">
            <h6 class="tit mt-2 mb-4 fw-bold">
                <p class="mb-1">{{item.title}}</p>
                <small class="small fw-lighter text-pale">{{item.date}}</small>
            </h6>
            <pre class="small pb-4 border-bottom">{{item.quest}}</pre>
            <pre class="small pt-4" v-if="item.answer != ''">{{item.answer}}</pre>
            <pre class="small pt-4 text-pale" v-else>답변이 없습니다</pre>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: "내 문의",
            item: {
                title: "내 입출금 내역이 안보여요",
                date: "5월 27일 08:30",
                quest: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt rerum quos quaerat numquam ad. Nulla nobis iusto voluptatem expedita cumque quia quis nemo ab labore ut error eos illo, blanditiis voluptas, voluptatibus id dolorum molestias. Dolores nihil accusantium quae debitis velit ullam pariatur cumque voluptatum impedit incidunt consectetur, adipisci facere dolore! Illo doloribus nulla, hic similique alias aliquid repellendus dolores quis provident, consequatur at. Praesentium veritatis, molestiae impedit similique quibusdam ex doloribus accusantium consectetur quas in saepe quidem voluptatibus debitis pariatur harum culpa adipisci sapiente ducimus cum! Fuga a at, repudiandae quisquam cumque consequuntur iure dolor perspiciatis nam necessitatibus!`,
                answer: `안녕하세요, 마이파킹입니다.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste nesciunt rerum quos quaerat numquam ad. Nulla nobis iusto voluptatem expedita cumque quia quis nemo ab labore ut error eos illo, blanditiis voluptas, voluptatibus id dolorum molestias. Dolores nihil accusantium quae debitis velit ullam pariatur cumque voluptatum impedit incidunt consectetur, adipisci facere dolore! Illo doloribus nulla, hic similique alias aliquid repellendus dolores quis provident, consequatur at. Praesentium veritatis, molestiae impedit similique quibusdam ex doloribus accusantium consectetur quas in saepe quidem voluptatibus debitis pariatur harum culpa adipisci sapiente ducimus cum! Fuga a at, repudiandae quisquam cumque consequuntur iure dolor perspiciatis nam necessitatibus!`
            }
        }
    },
}
</script>

<style>
</style>