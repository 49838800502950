<template>
    <div class="inquiry">
        <Header :title="title"></Header>
        <div class="container">
            <div class="pb-2 mb-5">
                <ul class="last-border-0">
                    <li class="menu_list_item" v-for="(item, index) in list" :key="index">
                        <router-link :to="'/cs/inquiry/detail/'+item.idx">
                            <div class="tit">
                                <p class="fw-bolder">{{item.title}}</p>
                                <span class="text-pale small">{{item.date}}</span>
                            </div>
                            <div class="sub">
                                <i class="fal fa-chevron-right"></i>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="footer_btn bg-white">
                <router-link to="/cs/inquiry/add" class="btn w-100 bg-pale text-primary">1:1 문의하기</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: "내 문의 내역",
            list: [
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
                {
                    idx: 0,
                    title: "내 입출금 내역은 어디서 볼 수 있나요?",
                    date: "5월 27일 08:30",
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .menu_list_item{
        font-size: 15px;

        a{
            align-items: center;
        }

        .tit{
            font-weight: 500;
        }
    }
</style>