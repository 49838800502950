<template>
    <div class="faq">
        <Header :title="title"></Header>
        <div class="container">
            <ul class="last-border-0">
                <li class="menu_list_item" v-for="(item, index) in often_faq" :key="index">
                    <router-link :to="'/cs/faq/detail/'+item.no">
                        <div class="tit">Q. {{item.question}}</div>
                        <div class="sub">
                            <i class="fal fa-chevron-right"></i>
                        </div>
                    </router-link>
                </li>
            </ul>
            <div>
                <router-link to="/cs/inquiry/add" class="btn w-100 bg-pale text-primary my-4">1:1 문의하기</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: "FAQ",
            often_faq: [
                {
                    no:0,
                    question:'아이디를 잊었습니다. 어떻게 찾을 수 있나요?'
                },
                {
                    no:1,
                    question:'환불받고 싶어요.'
                },
            ]
        }
    },
    mounted() {
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
    .menu_list_item{
        font-size: 15px;

        .tit{
            font-weight: 500;
        }
    }
</style>