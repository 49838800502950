<template>
    <div class="container">
        <h6>발급할 증명서를 선택해주세요</h6>
        <select class="form-select py-2 my-2" aria-label="증명서" v-model="certificate">
            <option value="예치금 잔액 증명서">예치금 잔액 증명서</option>
            <option value="DABS 잔고 증명서">DABS 잔고 증명서</option>
        </select>
        <div class="footer_btn">
            <button class="btn btn-primary w-100" @click="accessAgree">증명서 발급하기</button>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
export default {
    data(){
        return{
            title: "증명서 발급",
            certificate:'예치금 잔액 증명서'
        }
    },
    created(){
        eventBus.pageTitle(this.title);
    },
}
</script>

<style lang="scss" scoped>
    .menu_list_item{
        font-size: 15px;

        .tit{
            font-weight: 500;
        }
    }
</style>