<template>
    <div class="add_account">
        <Header :title="title"></Header>
        <div class="container">
            <div class="mb-3 small">
                <span class="text-grey">고객센터 운영시간<br>09:00 ~ 18:00, 주말 및 공휴일 제외</span>
            </div>

            <div class="input-group mb-2">
                <input type="text" class="form-control py-2" placeholder="제목" v-model="item.title">
            </div>
            <div class="input-group">
                <textarea v-model="item.content" class="form-control" :placeholder="placeholder"></textarea>
            </div>
            <div>
                <label class="btn bg-pale text-primary fw-bold w-100 mt-2" for="file"><i class="fas fa-plus-circle me-2"></i>파일 첨부하기</label>
                <input type="file" id="file" class="d-none" @change="onFileChange" multiple>

                <small class="text-pale" v-if="item.file == null"><span class="small">첨부된 파일이 없습니다.</span></small>
                <small class="text-pale text-truncate d-block" v-else>
                    <span class="small d-block" v-for="(itm,index) in item.file" :key="index">
                        {{itm.name}}　
                    </span>
                </small>
            </div>
            
            <div class="d-flex mt-3 mb-2">
                <div class="btn bg-primary text-white w-100" :class="{'disabled':item.content==''}" @click="addInquiry(true)">문의하기</div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: '1:1 문의',
            item: {
                title: "",
                content: "",
                file: null
            },
            placeholder: `문의하실 내용을 상세히 작성해주세요.
            
            답변은 평일 기준 1~2일 내에 앱을 통해 확인할 수 있습니다.

            문의내용 입력 시 주민등록번호, 전화번호 등 고객님의 개인 정보를 입력하지 않도록 유의해주세요.`
        }
    },
    created(){
    },
    methods:{
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (files.length){
                this.item.file = files;
            }
        },
        addInquiry(success){
            if( success ){
                this.$router.push('/complete/1')
            }else{
                this.$router.push('/complete/0')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-control{
        border-color: #e9e9e9;
        &::placeholder{
            font-size: 15px;
            color: #aaa;
        }
    }
    textarea{
        min-height: calc(100vh - 360px);

        &::placeholder{
            font-size: 14px;
        }
    }
</style>